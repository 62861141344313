<script lang="ts" setup>
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { FIVE_SURVEYS_REWARD } from '@/constants/locales';
import { computed } from 'vue';
import { transformCurrency } from '@/utils/helpers';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import ProgressBar from '@/components/common/ProgressBar.vue';

const { isMobile } = useScreenSize();

const { progress, fiveSurveysIncome, currency } = storeToRefs(useUserStore());

const incomeText = computed(() => {
	return `${transformCurrency(+fiveSurveysIncome.value! || 0, currency.value)}`;
});

const incomeAmount = computed(() => {
	return `${transformCurrency(+fiveSurveysIncome.value! || 0, currency.value)}`;
});
</script>

<template>
	<div class="surveys-progress">
		<div class="surveys-progress-header">
			<PrimeText :size="isMobile ? 'sm' : 'base'" color="grey-300">
				{{ $t(FIVE_SURVEYS_REWARD, { value: incomeText }) }}
			</PrimeText>
			<PrimeText
				:size="isMobile ? 'sm' : 'lg'"
				:weight="isMobile ? '400' : '500'"
				color="white"
			>
				{{ incomeAmount }}
			</PrimeText>
		</div>
		<ProgressBar :progress="progress" />
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.surveys-progress {
	margin-left: auto;
	margin-right: auto;
	padding-top: 0.625rem;
	padding-bottom: 1.135rem;
	max-width: 35rem;

	@include breakpoint(mobile) {
		padding-top: 2.25rem;
		padding-bottom: 2.25rem;
	}
}

.surveys-progress-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;

	@include breakpoint(mobile) {
		margin-bottom: 0.875rem;
	}
}
</style>
