<script lang="ts" setup>
import { LOGOUT, LOGOUT_QUESTION } from '@/constants/locales';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useRouter } from 'vue-router';
import { TEST_MODAL_LOGOUT_CONFIRM } from '@/constants/dataTestAttrs';

const router = useRouter();

const handleLogout = async () => {
	await router.replace('logout');
};
</script>

<template>
	<ModalCommonContent class="logout-modal">
		<template #header>{{ $t(LOGOUT) }}</template>
		<template #title>{{ $t(LOGOUT_QUESTION) }}</template>
		<template #footer>
			<PrimeButton
				type="text"
				variant="grey"
				rounded="md"
				:label="$t(LOGOUT)"
				:data-test="TEST_MODAL_LOGOUT_CONFIRM"
				@click="handleLogout"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.logout-modal {
	:deep(.content-title) {
		margin-bottom: 0;
	}
}
</style>
