<script setup lang="ts">
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { isMobile } = useScreenSize();
</script>

<template>
	<div class="error-wrapper">
		<div v-if="$slots.icon" class="error-icon">
			<slot name="icon"></slot>
		</div>
		<PrimeText
			v-if="$slots.title"
			tag="h2"
			:size="isMobile ? 'base' : '2xl'"
			weight="500"
		>
			<slot name="title"></slot>
		</PrimeText>
		<PrimeText
			v-if="$slots.message"
			tag="p"
			:size="isMobile ? 'sm' : 'base'"
			color="grey-700"
			class="error-message"
		>
			<slot name="message"></slot>
		</PrimeText>
		<div v-if="$slots.actions" class="error-actions">
			<slot name="actions"></slot>
		</div>
	</div>
</template>

<style scoped lang="scss">
@use '@/styles/mixins' as *;

.error-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	width: 80%;
	max-width: 20rem;
	text-align: center;

	@include breakpoint(mobile) {
		max-width: 22rem;
	}
}

.error-icon {
	margin-bottom: 0.75rem;
	line-height: 0;
}

.error-message {
	margin: 0.25rem 0 0.75rem;
}

.error-actions {
	:deep(.p-btn) {
		min-width: 8.125rem;
	}
}
</style>
