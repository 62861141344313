import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { isUserDev } from '@/utils/helpers';
import package_json from '../../../package.json';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { appLogStorage } from '@/utils/local-storage';

export const useDev = (): {
	isDev: ComputedRef<boolean>;
	appMeta: ComputedRef<{ version: string; api: string; platform: string }>
	packageVersion: ComputedRef<string>;
} => {
	const { platform } = useMobileApp();
	const userStore = useUserStore();
	const { email } = storeToRefs(userStore);

	const isDev = computed(() => {
		if (email.value) {
			appLogStorage.value = isUserDev(email.value);
		}
		return isUserDev(email.value);
	});

	const packageVersion = computed(() => package_json.version);
	const api = computed(() => import.meta.env.MODE);

	const appMeta = computed(() => ({
		version: packageVersion.value,
		platform: platform.value,
		api: api.value,
	}));

	return {
		isDev,
		appMeta,
		packageVersion,
	};
};
