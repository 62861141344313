<script lang="ts" setup>
import { ref } from 'vue';
import { authChangeEmail } from '@/api';
import type { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types';
import useVuelidate from '@vuelidate/core';
import {
	CHANGE_EMAIL,
	CONFIRM_EMAIL,
	EMAIL_TO_PREVIOUS,
	NEW_EMAIL,
	YOUR_PASSWORD,
	RESEND,
	CONFIRM,
	UNLINK_SOCIALS,
} from '@/constants/locales';
import {
	PrimeButton,
	PrimeInput,
	PrimeCooldownButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import ResetPasswordButton from '@/components/common/ResetPasswordButton.vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { PiEnvelopeDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import {
	TEST_MODAL_CHANGE_EMAIL_NEW,
	TEST_MODAL_CHANGE_EMAIL_PASSWORD,
	TEST_MODAL_CHANGE_EMAIL_CONFIRM,
} from '@/constants/dataTestAttrs';

const COOL_DOWN_SECONDS = 15;

type ChangeEmailForm = {
	email: string;
	password: string;
};

defineEmits<{
	(e: 'close-modal'): void;
}>();

const componentName = useComponentName();

const form = ref<ChangeEmailForm>({
	email: '',
	password: '',
});

const isRequestSent = ref(false);
const loading = ref(false);
const serverValidationResults = ref();
const cooldownButtonEl = ref<{ restartTimer: () => void } | null>(null);

const { email, password } = useValidationRules();
const validation = useVuelidate({ email, password }, form, {
	$autoDirty: false,
	$externalResults: serverValidationResults,
});

const {
	validate,
	getValidationFieldStatus,
	getValidationFieldText,
	isValidationError,
	clear,
} = useValidationUtils<ChangeEmailForm>(validation);

const handleChangeEmail = async () => {
	clear();
	await validate();
	if (isValidationError.value) return;
	try {
		loading.value = true;
		const { status } = await authChangeEmail(form.value);
		if (status === 200) {
			isRequestSent.value = true;
			cooldownButtonEl.value?.restartTimer();
		}
	} catch (error) {
		const errorData = (error as AxiosError).response?.data as ErrorResponse;
		serverValidationResults.value = {
			email: errorData.errors?.to_email,
			password: errorData.errors?.password,
		};
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent v-if="!isRequestSent">
		<template #header>{{ $t(CHANGE_EMAIL) }}</template>
		<template #notice>{{ $t(UNLINK_SOCIALS) }}</template>
		<template #inputs>
			<PrimeInput
				v-model="form.email"
				:placeholder="$t(NEW_EMAIL)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('email')"
				:error-text="getValidationFieldText('email')?.toString()"
				:input-attrs="{
					'data-test': TEST_MODAL_CHANGE_EMAIL_NEW,
				}"
			/>
			<PrimeInput
				v-model="form.password"
				type="password"
				:placeholder="$t(YOUR_PASSWORD)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
				:input-attrs="{
					'data-test': TEST_MODAL_CHANGE_EMAIL_PASSWORD,
				}"
			/>
			<ResetPasswordButton @click="$emit('close-modal')" />
		</template>
		<template #footer>
			<PrimeButton
				type="text"
				variant="grey"
				rounded="md"
				:label="$t(CONFIRM)"
				:data-test="TEST_MODAL_CHANGE_EMAIL_CONFIRM"
				@click="handleChangeEmail"
			/>
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
	<ModalCommonContent v-else class="text-center">
		<template #header>{{ $t(CONFIRM_EMAIL) }}</template>
		<template #icon>
			<PiEnvelopeDuotoneThin size="100%" color="base-primary" />
		</template>
		<template #title>{{ $t(EMAIL_TO_PREVIOUS) }}</template>
		<template #footer>
			<PrimeCooldownButton
				ref="cooldownButtonEl"
				data-test-id="change-email-resend-button"
				:text="$t(RESEND)"
				:timer="COOL_DOWN_SECONDS"
				@click="handleChangeEmail"
			/>
		</template>
	</ModalCommonContent>
</template>
