<script setup lang="ts">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { usePrimeChatIntegration } from '@/utils/composables/useIntegrationChatRender';

const CHAT_CONTAINER_ID = 'prime-chat';
const { renderPrimeChat } = usePrimeChatIntegration();
const { getAdminId } = storeToRefs(useAuthStore());

onMounted(() => {
	if (!getAdminId.value) {
		renderPrimeChat(`#${CHAT_CONTAINER_ID}`);
	}
});
</script>

<template>
	<div :id="CHAT_CONTAINER_ID" />
</template>
