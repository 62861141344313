<script setup lang="ts">
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import referrals from '@/assets/icons/referrals.svg';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import { TEST_MODAL_LOGOUT_CONFIRM } from '@/constants/dataTestAttrs';
import {
	REF_NOTIFICATION_MODAL_FOOTER,
	REF_NOTIFICATION_MODAL_HEADER,
	REF_NOTIFICATION_MODAL_MESSAGE,
	REF_NOTIFICATION_MODAL_TITLE,
} from '@/constants/locales';
import { useRouter } from 'vue-router';

const emits = defineEmits<{
	(e: 'close-modal'): void;
}>();

const userStore = useUserStore();
const router = useRouter();

onMounted(async () => {
	await userStore.fetchUserMeta();
});

const handleReferFriend = () => {
	router.push('/referrals');
	emits('close-modal');
};
</script>

<template>
	<div id="half-progress-modal">
		<ModalCommonContent class="text-center">
			<template #header> {{ $t(REF_NOTIFICATION_MODAL_HEADER) }} 💸 </template>
			<template #icon>
				<img :src="referrals" alt="" />
			</template>
			<template #title>
				<PrimeText size="lg" tag="p" weight="500">
					{{ $t(REF_NOTIFICATION_MODAL_TITLE) }} 🚀
				</PrimeText>
			</template>
			<template #message>
				<PrimeText color="grey-700" size="sm">
					{{ $t(REF_NOTIFICATION_MODAL_MESSAGE) }}
				</PrimeText>
			</template>
			<template #footer>
				<PrimeButton
					type="text"
					variant="grey"
					rounded="md"
					transform="uppercase"
					:label="$t(REF_NOTIFICATION_MODAL_FOOTER)"
					:data-test="TEST_MODAL_LOGOUT_CONFIRM"
					@click="handleReferFriend"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#half-progress-modal {
	:deep(.p-modal-layout) {
		.content-icon {
			width: 12.5rem;
			height: 12.5rem;
		}

		.content-description {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}
}
</style>
