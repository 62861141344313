import { useMediaQuery } from '@vueuse/core';

export const useScreenSize = () => {
	const breakpoints = {
		monitor: '(max-width: 1500px)',
		laptop: '(max-width: 1200px)',
		tablet: '(max-width: 1023px)',
		mobile: '(max-width: 767px)',
		mobileonly: '(max-width: 375px)',
	};

	const isMonitor = useMediaQuery(breakpoints.monitor);
	const isLaptop = useMediaQuery(breakpoints.laptop);
	const isTablet = useMediaQuery(breakpoints.tablet);
	const isMobile = useMediaQuery(breakpoints.mobile);
	const isMobileOnly = useMediaQuery(breakpoints.mobileonly);

	return { isMonitor, isLaptop, isTablet, isMobile, isMobileOnly };
}
