import API from '@/api';
import type { ProfileData, StatusResponse, MessageResponse } from '@/types';

type SubscriptionItem = {
	type: string;
	subscribed: boolean;
};

export const getUnauthEmailSubs = async (
	uuid: string,
	token: string
): Promise<SubscriptionItem[]> => {
	const { data } = await API.get<SubscriptionItem[]>(
		`/api/email-subscriptions?uuid=${uuid}&token=${token}`,
		{
			authRequired: false,
		}
	);
	return data;
};

export const getProfile = async (): Promise<ProfileData> => {
	const { data } = await API.get(`/api/profile`);
	return data;
};

export const getProfileEmailSubscriptions = async (): Promise<
	SubscriptionItem[]
> => {
	const { data } = await API.get<SubscriptionItem[]>(
		'/api/profile-email-subscriptions'
	);
	return data;
};

export const authProfileEmailSubs = async (
	subscriptions: SubscriptionItem[] | null = null
) => {
	const response = await API.put<{ message: string }>(
		`/api/profile-email-subscriptions`,
		{
			subscriptions: subscriptions,
		}
	);
	return response.data;
};

export const unauthProfileEmailSubs = async (
	uuid: string,
	token: string,
	subscriptions: SubscriptionItem[]
): Promise<void> => {
	await API.put(
		`/api/email-subscriptions`,
		{
			uuid,
			token,
			subscriptions,
		},
		{
			authRequired: false,
		}
	);
};

export const changeTimeZone = async (
	timezone: string
): Promise<{ message: string }> => {
	const response = await API.put<{ message: string }>(`/api/profile`, {
		timezone: timezone,
	});
	return response.data;
};

export const changeLanguage = async (language: string): Promise<string> => {
	const { data } = await API.put<{ message: string }>(`/api/profile/language`, {
		language,
	});
	return data.message;
};

export const changeHalfSurveys = async (
	value: boolean
): Promise<StatusResponse> => {
	const response = await API.put<MessageResponse>('/api/profile/half-surveys', {
		is_enabled: value,
	});

	return {
		status: response.status,
	};
};
