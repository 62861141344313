import Pusher from 'pusher-js';
import EchoCore from 'laravel-echo';

export class Echo {
	private static echoInstance: Echo;

	private constructor() {}

	public static getInstance() {
		if (!Echo.echoInstance) {
			Echo.echoInstance = new Echo();
		}
		return Echo.echoInstance;
	}

	public createConnection(token: string) {
		try {
			window.Pusher = Pusher;
			window.Echo = new EchoCore({
				cluster: '', // Needed for pusher package requirements
				broadcaster: 'pusher',
				key: import.meta.env.VITE_PUSHER_KEY,
				wsHost: import.meta.env.VITE_PUSHER_HOST,
				wsPort: import.meta.env.VITE_PUSHER_PORT,
				wssPort: import.meta.env.VITE_PUSHER_PORT,
				forceTLS: false,
				encrypted: true,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				channelAuthorization: {
					endpoint: `${import.meta.env.VITE_API_HOST}/broadcasting/auth`,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			});
		} catch (error) {
			throw new Error('Failed to create WebSocket connection.');
		}
	}

	public isCurrentConnection(channelName: string, token: string): boolean {
		if (!window.Echo) {
			return false;
		}

		const channels = window.Echo.connector.channels;
		const channelExists = Object.keys(channels).includes(channelName);

		const currentToken =
			window.Echo.options.channelAuthorization.headers.Authorization;

		return channelExists && currentToken === `Bearer ${token}`;
	}

	public removeConnections() {
		window?.Echo?.leaveAllChannels();
		delete window.Pusher;
		delete window.Echo;
	}
}
