<script setup lang="ts">
import { computed } from 'vue';
import { getRootDomain } from '@/utils/helpers';
import logo from '@/assets/logo.svg';
import LanguageSwitcher from '@/views/app-login/components/LanguageSwitcher.vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';

const { isMobileApp, platform } = useMobileApp();

const homepageUrl = computed(() => {
	const rootDomain = getRootDomain();
	if (import.meta.env.MODE === 'development') {
		return `http://${rootDomain}:3001/`;
	} else {
		return `https://${rootDomain}`;
	}
});

const offsetTop = computed(() => {
	if (platform.value === 'ios') {
		return '133px';
	} else {
		return '74px';
	}
});
</script>

<template>
	<header class="login-header">
		<div class="login-header-logo">
			<a v-if="!isMobileApp" :href="homepageUrl">
				<img :src="logo" alt="Five Surveys logo" />
			</a>
			<img v-else :src="logo" alt="Five Surveys logo" />
		</div>
		<div class="login-header-lang">
			<LanguageSwitcher />
		</div>
	</header>
</template>

<style scoped lang="scss">
.login-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: calc(0.75rem + env(safe-area-inset-top)) 0 0.75rem;
	width: 100%;
	max-width: 90rem;
	min-height: v-bind(offsetTop);
}

.login-header-logo {
	line-height: 0;

	a {
		display: inline-block;
		vertical-align: top;
	}

	img {
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		height: auto;
	}
}
</style>
