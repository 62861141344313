import { AUTH_ROUTE_NAME } from '@/constants/routes';
import {
	checkExistingSocialsHook,
	checkResetPasswordHook,
	checkSignUpCompleteHook,
	checkSocialAuthHook,
	checkUserEmailHook,
	forgotPasswordHook,
} from '@/router/hooks';

export const authRoutes = [
	{
		path: '/',
		name: AUTH_ROUTE_NAME.HOME,
		component: () => import('@/views/app-login/AppLoginView.vue'),
		meta: {
			authRoute: true,
			appLogin: true,
		},
		children: [
			{
				path: '/app-login',
				name: AUTH_ROUTE_NAME.APP_LOGIN,
				component: () => import('@/views/app-login/AppLoginView.vue'),
				meta: {
					mobileNavHidden: true,
					authRoute: true,
					appLogin: true,
				},
			},
		],
	},
	{
		path: '/localization',
		name: AUTH_ROUTE_NAME.LOCALIZATION,
		component: () => import('@/views/localization/LocalizationView.vue'),
		meta: {
			authRoute: true,
		},
	},
	{
		path: '/signin',
		name: AUTH_ROUTE_NAME.SIGN_IN,
		component: () => import('@/views/signin/SigninView.vue'),
		meta: {
			authRoute: true,
		},
		beforeEnter: checkUserEmailHook,
	},
	{
		path: '/forgot-password',
		name: AUTH_ROUTE_NAME.FORGOT_PASSWORD,
		component: () => import('@/views/forgot-password/ForgotPasswordView.vue'),
		meta: {
			authRoute: true,
		},
		beforeEnter: forgotPasswordHook,
	},
	{
		path: '/signup',
		name: AUTH_ROUTE_NAME.SIGN_UP,
		component: () => import('@/views/signup/SignupView.vue'),
		meta: {
			authRoute: true,
		},
		beforeEnter: checkUserEmailHook,
	},
	{
		path: '/signup-complete',
		name: AUTH_ROUTE_NAME.SIGN_UP_COMPLETE,
		component: () => import('@/views/signup-complete/SignupCompleteView.vue'),
		meta: {
			authRoute: true,
		},
		beforeEnter: checkSignUpCompleteHook,
	},
	{
		path: '/socials',
		name: AUTH_ROUTE_NAME.SOCIALS,
		component: () => import('@/views/socials/SocialsView.vue'),
		meta: {
			authRoute: true,
		},
		beforeEnter: checkExistingSocialsHook,
	},
	{
		path: '/auth/social',
		name: AUTH_ROUTE_NAME.AUTH_SOCIAL,
		component: () => import('@/views/auth-social/AuthSocialView.vue'),
		meta: {
			ignoreAuth: true,
			authRoute: true,
		},
	},
	{
		path: '/signup-social',
		name: AUTH_ROUTE_NAME.SIGN_UP_SOCIAL,
		component: () => import('@/views/signup-social/SignupSocialView.vue'),
		meta: {
			authRoute: true,
		},
		beforeEnter: checkSocialAuthHook,
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import('@/views/reset-password/ResetPasswordView.vue'),
		meta: {
			mobileNavHidden: true,
			ignoreAuth: true,
			authRoute: true,
		},
		beforeEnter: checkResetPasswordHook,
	},
];
