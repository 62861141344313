<script setup lang="ts">
import type { RewardsItem } from '@/types';
import RewardImage from '@/components/common/RewardImage.vue';
import { transformCurrency } from '@/utils/helpers';
import {
	PrimeAccordion,
	PrimeAccordionTab,
	PrimeButton,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

withDefaults(
	defineProps<{
		item: RewardsItem;
		open?: boolean;
		selectedOptionId?: number;
	}>(),
	{
		open: false,
		selectedOptionId: 0,
	}
);

defineEmits<{
	(e: 'select-option', $event: number): void;
}>();

const { currencyBalance } = storeToRefs(useUserStore());
</script>

<template>
	<PrimeAccordion class="reward-item">
		<PrimeAccordionTab>
			<template #title>
				<span class="partner-info">
					<PrimeText size="sm" transform="capitalize">
						{{ item.name }}
					</PrimeText>
					<RewardImage :src="item.image_url" :alt="item.name" />
				</span>
			</template>
			<template #content>
				<div class="reward-options">
					<PrimeButton
						v-for="option in item.options"
						:key="option.coin_value"
						class="reward-option"
						:class="{ selected: selectedOptionId === option.id }"
						type="outline"
						variant="grey"
						rounded="xl"
						:label="transformCurrency(option.money_value, option.currency)"
						:disabled="+currencyBalance < +option.money_value"
						@click="$emit('select-option', option.id)"
					/>
				</div>
			</template>
		</PrimeAccordionTab>
	</PrimeAccordion>
</template>

<style scoped lang="scss">
@use '@/styles/mixins' as *;

.reward-item {
	margin-bottom: 0.25rem;

	:deep(.p-accordion-tab) {
		border-color: var(--p-grey-50);
		border-radius: var(--5s-radius-xl);

		.p-accordion-button {
			padding: 0.625rem 0.875rem;
			border-color: transparent;
			border-radius: var(--5s-radius-xl);

			&:focus {
				outline: none;
			}

			& > .p-text {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

			.p-accordion-icon {
				display: none;
			}

			.p-accordion-content {
				padding-top: 0;
			}
		}
	}
}

.partner-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	width: 100%;
}

.reward-options {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.75rem;
}

.reward-option {
	padding: 0.75rem 0.5rem;
	box-shadow: var(--5s-shadow-button);

	&.p-btn {
		&.p-btn--grey {
			&.p-disabled {
				color: var(--p-grey-300);
				background: var(--p-white);
			}

			&:focus,
			&.selected {
				box-shadow: inset 0 0 0 2px var(--p-text-default);
			}
		}
	}

	:deep(.p-btn-label) {
		font-weight: 600;
		font-size: var(--p-font-18);
	}
}
</style>
