<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { PiTranslate } from '@primeinsightsgroupllc/prime-icons';
import { useRouter } from 'vue-router';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const router = useRouter();
const authStore = useAuthStore();
const { localizationText } = storeToRefs(authStore);
</script>

<template>
	<button
		class="language-switcher"
		@click="router.push({ name: 'localization' })"
	>
		<PiTranslate class="icon" color="inherit" />
		<PrimeText size="sm">{{ localizationText }}</PrimeText>
	</button>
</template>

<style lang="scss" scoped>
.language-switcher {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.2;
	padding: 0.3125rem 0.75rem;
	color: var(--p-text-default);
	border: 1px solid var(--p-grey-700);
	background: transparent;
	transition: background 0.2s;
	border-radius: 3.125rem;

	&:hover,
	&:focus {
		background: var(--p-grey-50);
	}

	.icon {
		margin-right: 0.5rem;
	}

	& .p-text {
		line-height: 1.2;
	}
}
</style>
