<script lang="ts" setup>
import { NEW_PASSWORD_SENT } from '@/constants/modals';
import { useAuthStore } from '@/stores/auth';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { NEED_NEW_PASSWORD } from '@/constants/locales';
import { PiLock } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { TEST_NEED_NEW_PASSWORD } from '@/constants/dataTestAttrs';
import { USER_ROUTE_NAME } from '@/constants/routes';

const emit = defineEmits<{
	(e: 'click'): void;
}>();

const loading = ref(false);

const componentName = useComponentName();
const authStore = useAuthStore();
const { email } = storeToRefs(useUserStore());
const { createNewModal } = useModalStorage();

const handleClick = async () => {
	try {
		loading.value = true;
		const response = await authStore.forgotPassword(email.value!);

		if (response?.status === 200) {
			await createNewModal(NEW_PASSWORD_SENT, {
				scopedToRoute: USER_ROUTE_NAME.SETTINGS,
			});
			notify({
				body: response.data.message,
			});
			emit('click');
		} else {
			notify({ body: authStore.error?.message || '' });
		}
	} catch (error) {
		notify({ body: authStore.error?.message || '' });
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<PrimeButton
		class="need-password-button"
		type="link"
		size="small"
		:label="$t(NEED_NEW_PASSWORD)"
		:data-test="TEST_NEED_NEW_PASSWORD"
		@click="handleClick"
	>
		<template #iconLeft>
			<PiLock color="inherit" />
		</template>
	</PrimeButton>
	<PrimeLoader v-if="authStore.loading" />
</template>

<style lang="scss">
.need-password-button {
	margin-top: 0.75rem;
}
</style>
