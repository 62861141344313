<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { RewardsItem } from '@/types';
import { categories } from '@/views/surveys/components/user-dashboard/rewards/categories';
import RewardItem from '@/views/surveys/components/user-dashboard/rewards/RewardItem.vue';
import { useI18n } from 'vue-i18n';
import { VIEW_MORE_REWARDS } from '@/constants/locales';
import { RewardCategories } from '@/enums';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = defineProps<{
	items: RewardsItem[];
	category: RewardCategories;
	selectedOption: number;
}>();

defineEmits<{
	(e: 'select-option', $event: number): void;
}>();

const { t } = useI18n();
const isFullListVisible = ref(false);

const expandButtonText = computed(() => {
	const itemsLeftToShow = props.items.length - 2;
	return `${t(VIEW_MORE_REWARDS, {
		value: itemsLeftToShow,
	})} ${t(categories[props.category].name, itemsLeftToShow).toLowerCase()}`;
});

const categoryName = computed(() => {
	const transfer = props.category === RewardCategories.TRANSFERS ? 1 : 2;
	return `${t(categories[props.category].name, transfer)} (${
		props.items.length
	})`;
});

const visibleRewards = computed(() => {
	return isFullListVisible.value ? props.items : props.items.slice(0, 2);
});

watch(
	() => props.items,
	(value) => {
		isFullListVisible.value = value.length <= 2;
	},
	{ immediate: true }
);
</script>

<template>
	<div class="reward-category">
		<div class="category-header">
			<component :is="categories[category].icon" size="1.25rem" />
			<PrimeText size="xs" weight="500" transform="uppercase">
				{{ categoryName }}
			</PrimeText>
		</div>
		<RewardItem
			v-for="reward in visibleRewards"
			:key="reward.reward_external_id"
			:item="reward"
			:selected-option-id="selectedOption"
			@select-option="$emit('select-option', $event)"
		/>
		<PrimeButton
			v-if="!isFullListVisible"
			type="link"
			size="small"
			:label="expandButtonText"
			@click="isFullListVisible = true"
		/>
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins' as *;

.reward-category {
	margin-bottom: 0.875rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.category-header {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.625rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	margin-bottom: 0.25rem;
}
</style>
