import {
	email,
	helpers,
	maxLength,
	minLength,
	required,
	sameAs,
} from '@vuelidate/validators';
import {
	CODE_REQUIRED,
	CONFIRM_PASSWORD_REQUIRED,
	EMAIL_NOT_VALID,
	EMAIL_REQUIRED,
	PASSWORD_MIN_LENGTH,
	PASSWORD_REQUIRED,
	FULL_NAME_MAX_LENGTH,
	FULL_NAME_MIN_LENGTH,
	FULL_NAME_REQUIRED,
	PASSWORD_NOT_EQUAL,
	TAG_MAX_LENGTH,
	TAG_MIN_LENGTH,
	TAG_REQUIRED,
} from '@/constants/locales';
import { useI18n } from 'vue-i18n';
import type { ComputedRef } from 'vue';

export const useValidationRules = () => {
	const { t } = useI18n();

	return {
		email: {
			required: helpers.withMessage(t(EMAIL_REQUIRED), required),
			email: helpers.withMessage(t(EMAIL_NOT_VALID), email),
		},
		password: {
			required: helpers.withMessage(t(PASSWORD_REQUIRED), required),
			minLength: helpers.withMessage(t(PASSWORD_MIN_LENGTH), minLength(8)),
		},
		password_confirmation: (passwordValue: ComputedRef<string>) => ({
			required: helpers.withMessage(t(CONFIRM_PASSWORD_REQUIRED), required),
			sameAsPassword: helpers.withMessage(
				t(PASSWORD_NOT_EQUAL),
				sameAs(passwordValue, 'password')
			),
		}),
		policyAccepted: {
			sameAs: sameAs(true),
		},
		code: {
			required: helpers.withMessage(t(CODE_REQUIRED), required),
		},
		fullName: {
			required: helpers.withMessage(t(FULL_NAME_REQUIRED), required),
			minLength: helpers.withMessage(t(FULL_NAME_MIN_LENGTH), minLength(3)),
			maxLength: helpers.withMessage(t(FULL_NAME_MAX_LENGTH), maxLength(255)),
		},
		tag: {
			required: helpers.withMessage(t(TAG_REQUIRED), required),
			minLength: helpers.withMessage(t(TAG_MIN_LENGTH), minLength(3)),
			maxLength: helpers.withMessage(t(TAG_MAX_LENGTH), maxLength(255)),
		},
	};
};
