<script lang="ts" setup>
import { useRoute } from 'vue-router';
import LoginHeader from '@/views/app-login/components/LoginHeader.vue';
import MainHeader from '@/components/common/header/MainHeader.vue';

const route = useRoute();
</script>

<template>
	<LoginHeader v-if="route.meta.appLogin" />
	<MainHeader v-if="!route.meta.appLogin && !route.meta.authRoute" />
</template>
