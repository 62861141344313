import { sendDeviceId } from '@/api';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';

export const initNotifications = () => {
	// Request permission to use push notifications
	// iOS will prompt user and return if they granted permission or not
	// Android will just grant without prompting
	FirebaseMessaging.requestPermissions().then(async (result) => {
		if (result.receive === 'granted') {
			// Register with Apple / Google to receive push via APNS/FCM
			try {
				const res = await FirebaseMessaging.getToken();
				console.error('Push registration success, token: ' + res.token);
				await sendDeviceId(res.token);
			} catch (e) {
				console.error('Error on registration: ' + e);
			}
		} else {
			// Show some error
			console.error('Error on registration: ' + JSON.stringify(result));
		}
	});
	FirebaseMessaging.addListener('notificationReceived', (event) => {
		console.log('notificationReceived: ', { event });
	});
	FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
		console.log('notificationActionPerformed: ', { event });
	});
};

export const removeAllNotifListeners = async () => {
	await FirebaseMessaging.removeAllListeners();
};
